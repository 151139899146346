@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@200&family=Montserrat:wght@300;400;500;600&display=swap');


.FinalSixBold {
	font-family: 'FinalSixBold';
}
	


*{
  font-family: 'Montserrat', sans-serif  ;
  
  
}





.MuiTypography-root{
  font-family: 'Montserrat', sans-serif  !important;
}


.image {
  background-size: cover;
  overflow-y: hidden;
  overflow-x: hidden;

  -webkit-background-size: auto;
}

.navbar{
  padding-top: none !important;
}

.navbar1 {
  position: relative !important;
  top: 0%;
  z-index: 10 !important;
  width: 100%;
  background-color: rgba(255, 255, 255, 0) !important;
  padding-top: 0 !important;


  
  justify-content: space-between !important;
}

.navbar2 {
  top: 0%;
  position: fixed !important;
  width: 100%;
  background-color: #000000 !important;
  border-top: 0px !important;
  height: 3.5rem;
  z-index: 10 !important;
}

.mobilenav{
  height: 13rem;
  background-color: #000000;
  padding-bottom: 1rem;
  position: relative;
}

.mobilelist{
  color: white;
  text-align: center !important;
  list-style-type: none;
  margin: none !important;
  font-size: 1.2rem;
  position: relative;
}

.mobilelist li {
  margin-bottom: .4rem;
}



.mobnav-enter {
  opacity: 1;

  transform: translateY(50%);
}
.mobnav-enter-active {
  opacity: 1;
  transform: translateY(50%);
  transition: opacity 300ms, transform 300ms;
}
.mobnav-exit {
  opacity: 0;
  transform: translateY(25%);
}




.mobnav-exit-done {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }

    50%{

    opacity: .5;
    transform: translateY(-.5rem);
    }

    75% {
      opacity: 1;
      transform: translateY(0rem);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  








@media screen and (max-width: 600px) {
.imagecont{
height: 20vh !important;

  
}

.carousel{
  max-width: 85vw !important;
  width: 85vw !important;
}
}


.container1{
  max-width: 100% !important;
  justify-content: center !important;

}


.footer{
  text-align: center;
  font-size: .8rem;
  
  clear: both;
  margin-bottom: 1rem;
  color: rgb(76, 76, 76);
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: flex-end;
  margin-top: 1rem;
 
  
}

.footerlink{
  color: rgb(103, 164, 255);
  cursor: pointer;
  text-decoration: none;
}


.footerlink:hover{
  color: rgb(93, 93, 93);
}


.container2{
  max-width: 100% !important;
}

.title{
  margin-left: 2rem !important;
}

.labtitle{
  margin-top: 1.5rem !important;
  text-align: center;
  text-decoration: none !important;
  margin-bottom: 1vh !important;
  font-size: 2rem;
  font-weight: 800;


}

.mobiletitle {
  font-size: 2.2rem;



}

.labtitlelink{
  text-decoration: none;
  color: black;
  font-family: 'FinalSixBold'
}

.labtitlelink:hover{
  color: rgb(67, 67, 67);
  border-bottom: 1px black solid;
  box-sizing: border-box !important;
}


@media screen and (max-width: 600px) {


.labtitlelink:hover{
  color: rgb(0, 0, 0);
  border-bottom: none !important;
  box-sizing: border-box !important;
}



.labtitlelink:active{
  border-bottom: 1px black solid !important;
  box-sizing: border-box !important;
}

}



::-webkit-scrollbar-track { 
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #777777;
  border-radius: 1rem;
  border: .3rem solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  width: 1rem;
  
}



.text1 {
  color: #000000 !important;
  padding-bottom: 1.5px !important;
  margin-right: 1rem !important;
  margin-left: .5rem;
  font-size: .9rem;
  font-family: 'FinalSixBold'
}

.text1:hover {
  border-bottom: 1px black solid !important;
  box-sizing: border-box !important;
  padding-bottom: 0.5px !important;
  font-style: bold !important;
  color: rgb(87, 87, 87) !important;
}

.text2:hover {
  border-bottom: 1px rgb(225, 225, 225) solid !important;
  padding-bottom: 0.5px !important;
  font-style: bold !important;
  color: rgb(225, 225, 225) !important;
}

.text2 {
  color: rgb(255, 255, 255) !important;
  padding-bottom: 1.5px !important;
  margin-right: 1rem !important;
  margin-left: .5rem;
  font-size: .9rem;
  font-family: 'FinalSixBold'
}

.alert-enter {
  opacity: 0;

  transform: translateY(-50%);
}
.alert-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 0;
  transform: translateY(25%);
}




.alert-exit-done {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
  }



  @media screen and (max-width: 600px) {

    .next{
      right: -8% !important;
      top: 35% !important;
      
    }
    
    .prev{
      left: -8% !important;
      top: 35% !important;
     
    }

  }

  
.arrow{
  cursor: pointer;
  position: absolute;
  z-index: 10;
}

.next{
  right: -5%;
  top: 45%;
}

.prev{
  left: -5%;
  top: 45%;
}


@media screen and (max-width: 600px) {
.slick-slide{
  width: 80vw !important;
}

.slick-slider {
  width: 80vw !important;
}

.carouselpic {
  width: 80vw !important;
  height: auto
}
  
}

.slidercontainer{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.carouselpic {
  width: 60vw;
  height: auto
}


.slick-slide {
  width: 60vw;
}

.slick-slider {
  width: 60vw;
}
.car{
  z-index: 0 !important;
}


.imagecont {
  height: 23%;
}




.background1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto; /* This makes the content div grow to occupy available space */
}

.lg {
  width: 50vw;
  height: 50vh;
  margin: auto;
  margin-top: 20vh;
  margin-bottom: 4rem;
}

@media screen and (max-width: 600px) {


  .listContainer{
    flex-direction: column !important;
    margin-left: 2.5rem !important;
   
  }

  .list{
    width: 80vw !important;
    position: relative !important;
    text-align: center !important;
    
  }

 
  .footer{
    width: 85vw !important;
    flex-direction: column !important;
    margin-left: 2rem !important;
  }

  .footerlink {
    margin-bottom: 1rem !important;
  }

  .text2{
    font-size: 1.1rem !important;
    margin: none !important;
  }

  .text2:hover{
    border-bottom: none!important;
    padding-bottom: 0.5px !important;
    color: gray !important;
  font-size: 1.2rem !important;
  }
  .textactive{
    
  color: gray !important;
  font-size: 1.2rem !important;
  padding-bottom: 0.5px !important;
  }

  .pubtitle{
    
    margin-top: 2rem !important;
    font-weight: bold !important;
  }
  .mobiletext{
    margin-left: none !important;
  }

  .researchtext{
    width: 85vw !important;
    margin-left: 1rem !important;
 
  }
  .researchimage{
    width: 80vw !important;
    height: auto !important;
  }

  .piccontainer{
    width: 80vw !important;
    flex-direction: column !important;
  
    
  }

  .contacttext{
    font-size: .8rem !important;
  }

  .imagecontact{
    width: 80vw !important;
    height: auto !important;
    
  }

  .mapcontainer{
   
    width: 80vw !important;
  }

  .ccard{
    width: 80vw !important;
    height: auto !important;
   position: relative !important;
   margin-right: 0 !important;
   
  }
  .cardcontainer{
   align-items: center;
   width: 100vw;
    
  }

  .imagecontainer{
    display: flex;  
    flex-direction: column;
    justify-content: center !important;
    width: 100vw;
    align-items: center !important;
  }

  .card-title{
    text-align: center !important;
  }

  .imagetext{
    width: 80vw !important;
    text-align: center !important;
  }


  
 

  }
  
  .progress_bar{
    position: absolute;
    top: 0;
  }
  
  .contacttext{
    font-size: .9rem 
  }
  .mapcontainer{
    width: 60vw ;
  }



.listContainer{
  display: flex ;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3rem;
  max-width: 70%;
  margin: auto;
}

.listHeader{
color: white !important;
  background-color: rgb(0, 0, 0) !important;
  position: relative !important;
  font-family: 'FinalSixBold' !important;
  
  
}

.MuiListSubheader-root{
  font-size: 1rem !important;
}


.list{
  width: 30vw
}

.listitm{
  margin: none !important;
  padding: none !important;
}

.icon{
  min-width: 2rem !important;
  align-self: flex-start;
  margin-top: .5rem;
}

.icon2{
  color:#737373
}

.icon2:hover{

  color: black;
}



.icon:hover{
  color: black;
  cursor: pointer;
}

.MuiTypography-root{
  font-size: .9rem !important;
}


/* contact page */

.cards{
  width: 60vw;
background-color: #ffffff !important;
  margin-top: 1rem;
  
  
  font-size: .8rem;

  transition: box-shadow .3s;
  border-bottom: 1px rgb(130, 130, 130) solid;
  margin-bottom: .5rem;
}

.card:hover{
  
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.cardcontainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.piccontainer{
  display: flex;
  justify-content: center;
}

.imagecontact{
  margin-top: 1rem;
  width: auto;
  height: 40vh;

}

.imagetext{
  font-size: .7rem;
  margin-top: 1rem;
}
.imagetext2{
  font-size: .8rem;
}

.card-title{
  font-weight: 500 !important;
}


.title1{
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 800;
  font-size: 1.3rem;
  color: #1b263b;
  font-family: 'FinalSixBold'
}

.lastcard{
  margin-bottom: 1rem;
  border-bottom: none !important;
}




/* People page */

.peoplepic{
  height: 6rem;
  width: 6rem;
}


@media screen and (max-width: 600px) {
 
  [dataAttribute='overflow'] {
    font-size: 0.7rem !important;
  }

  .peoplepics{
    width: 14rem !important;
  
    height: 14rem !important;
    
    
    
  }

  
  .p1{
   
    flex-direction: column !important;
    align-items: center !important;

   
  }

  .person{
    margin-right: 0 !important;
  }

  .mobile_text_long{
    font-size: .75rem !important;

  }
  

  .cardpic{
    height: 14rem !important;
    width: 14rem !important;

    overflow: hidden !important;
  }

  .mcardtext{
    font-size: .9rem;
  }

  .mcardtitle{
    font-size: 1rem !important;
  }

  .picturecontainer{
    width: 14rem !important;
    height: 14rem !important;
  }
.people-title{
  margin-left: 0 !important;
}
  

 
  }

  .mcardtext{
    font-size: .9rem;
    
  }
 

  .phdpeople:last-of-type{
    padding-left: 1rem;
  }

  .peopletitle{

                  text-align: center;
                  
  }

  .peoplecontainer{
    display: flex;
    
    flex-direction: row;
    justify-content: center;
  }

.p1{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
 width: 70vw;

 
  
  

}

.rowcontainer{
  display: flex;

}

.pimages{
  margin-bottom: 2rem;
  z-index: 0
  
}

.person{
  margin-right: 2rem;
}


.person:last-of-type{
  margin-right: 0;
}



.peoplepics{
  width: 15rem;
  margin-right: 0 !important;
  
  will-change: transform, opacity;
  cursor: pointer;
  max-height: 15rem;
  height: 15rem ;

  
  
}


[dataAttribute='overflow'] {
  font-size: .8rem;
}

.phdcontainer{
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.cardpic{
  height: 15rem;
  width: 15rem;
  margin-right: 0;
  max-height: 15rem !important;
  
}

.card{
  background-color: #778da92d !important;
}

.people-title{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  font-size: .8rem;
}

.cardText{
  min-height: 15rem;
  overflow-y: scroll;
  overflow-x: hidden;

    
}

.cardText::-webkit-scrollbar {
  width: .8rem;  /* Change width of the scrollbar */
}



/* Publications page */






.pubpic{
  height: 4rem;
  width: 3rem;
}


.pubimage {
	position: relative;
}
.pubimage:hover::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.pubimage::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}


.pub{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pubcontainer{
  display: flex;
  flex-direction: row;

  width: 70vw;
  margin-top: 2rem;
}

.pubcontainer:last-of-type{
  margin-bottom: 2rem;
}

.pubtext{
  font-size: .8rem;
  margin-left: 1rem;
align-self: center;
 
}


.pubtitle{
  text-align: center;
  margin-top: 1.5rem;
  font-weight: bold;
  font-family: 'FinalSixBold'
}


/* Research page */

.researchcontainer{
  
display: flex;
flex-direction: column;
align-items: center;
}

.researchtext{
  width: 60vw;
  font-size: .9rem;
  margin-top: 1rem;
  line-height: 1.5rem;
}



.researchimg-container{
  width: 100%;
  display: flex;
  justify-content: center;
  
  padding: 1rem;
  margin-bottom: 1rem;
  
}

.researchimage{
  width: auto;
  height: 13rem;
 
  box-shadow: -7px 12px 22px 3px rgba(0,0,0,0.52);
  



}

.researchborder{
  border-bottom: 1px rgb(130, 130, 130) solid;
}


/* news page */


@media screen and (max-width: 600px) {
 .newsitm{
  width: 80vw !important;
  margin-left: 2rem !important;
  margin-bottom: .5rem !important;
  
 }

 .newsdate{
   width: 1rem !important;
   margin-right: 1.5rem !important;
 }

 .newstext{
   align-self: center !important;
   width: 80vw !important;
 }

 .pubtext{
  font-size: .7rem !important;
 }
  }
  

  .newscont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* This will allow the cards to wrap */
    align-items: flex-start; /* Align items to the start of the container */
  text-align: center;
    justify-content: center; /* Center the cards within the container */
  
  }

  .card{
    background-color: white !important;
    border-width: 1px !important;
    border-color: #5e5e5e !important;
    border-radius: 0% !important;
  }
  .card-size{
    height: 11rem !important;
  }
  .newspiece {
    display: flex;
    flex-direction: column;
    max-width: 20rem; /* Adjust max-width as needed for the cards */
    
    width: 100%; /* This makes sure that the card is responsive */
    margin: 10px; /* This adds margin around each card */
  }

.newsdate{
  margin-right: 1rem;
  width: 4vw;
}

.newsitm{
  display: flex;
  justify-content: flex-start;
  width: 45vw;
 font-size: .8rem;
 margin-left: 3rem;


}

.newsitm:last-of-type{
  margin-bottom: 1rem;
}

.newstext{
  font-size: .9rem;
  width: 40vw;
}


/*LOGIN PAGE*/

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
margin-top: 5rem;
}

.admin-form {
  width: 25%;
}

/*ADMIN PAGE */

.adminlink{
  color: white;
  cursor: pointer;
  text-decoration: none;
  width: 20rem;
  align-self: center;
  margin-top: 1rem;
}

.adminbutton{
  width: 20rem;
  align-self: center;

}

.button-container {
  text-align: center;
  margin-top: 1rem;  
}



.backbutton {
  
 width: 10rem;
}



.adminbuttoncontainer{
  display: flex;
  flex-direction: column;
}

.my-toast .Toastify__progress-bar {
  background: black; /* Change this to your desired color */
}

.my-toast {
  --toastify-icon-color-error: black;
}

/*Admin News */
.tablecontainer{
  width: 80vw;
  margin: 0 auto;
  margin-top: 2rem;
}


.loader-container {
  width: 100%;
  height: 4px; /* Thinner bar */
  position: fixed; /* Fixed position */
  top: 0; /* At the top of the page */
  left: 0;
  z-index: 9999; /* Ensure it's above other elements */
  background-color: transparent; /* Transparent background */
  overflow: hidden;
}

.loader-bar {
  height: 100%;
  width: 0%;
  background: linear-gradient(90deg, #ff7e5f 0%, #feb47b 100%); /* Orange gradient */
  animation: load 1.5s ease-in-out infinite; /* Keep the animation speed */
}

@keyframes load {
  0% { width: 0%; }
  50% { width: 100%; }
  100% { width: 0%; }
}



/* NEws pAge

.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
}

.timeline-item {
  padding: 20px;
  position: relative;
  width: 50%;
}

/* Timeline items on the left side */
.timeline-item.left {
  left: 0;
}

/* Timeline items on the right side */
.timeline-item.right {
  left: 50%;
}

/* The actual timeline (a vertical line) */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #333;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.timeline-content {
  background: white;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.timeline-image {
  max-width: 100%;
  height: auto;
}

.date {
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  color: #666;
}

/* Responsive adjustments */
@media screen and (max-width: 600px) {
  .timeline-item {
    width: 100%;
    padding-left: 70px; /* Give some space for the timeline */
    padding-right: 25px;
  }

  .timeline-item.right {
    left: 0;
  }

  .timeline-container::after {
    left: 60px; /* Adjust based on the padding-left of .timeline-item */
  }
}
